import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

// Global Styles
const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Courier New', monospace;
  }
`;

// Helmet configuration for SEO
const SEOConfig = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>Cannacoin</title>
    <link rel='canonical' href='http://cannacoin.org/' />
  </Helmet>
);

// Lazy loading components
const Nav = lazy(() => import('./Nav'));
const Hero = lazy(() => import('./Hero'));
const QA = lazy(() => import('./QA'));
const Why = lazy(() => import('./Why'));
const How = lazy(() => import('./How'));
const Acquire = lazy(() => import('./Acquire'));
const About = lazy(() => import('./About'));
const OurMission = lazy(() => import('./OurMission'));
const OurTeam = lazy(() => import('./OurTeam'));
const Footer = lazy(() => import('./Footer'));
const MemorialCard = lazy(() => import('./MemorialCard'));
const CountdownTimer = lazy(() => import('./CountdownTimer'));

function App() {
  return (
    <div>
      <SEOConfig />
      <GlobalStyle />
      <Suspense fallback={<div>Loading...</div>}>
        <Nav />
        <Hero />
        <QA />
        <Why />
        <How />
        <Acquire />
        <About />
        <OurMission />
        <OurTeam />
        <Footer />
        <MemorialCard />
        <CountdownTimer />
      </Suspense>
    </div>
  );
}

export default App;
